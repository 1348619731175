
import ContentBlockHeader
    from 'portal/components/common/content-block/ContentBlockHeader.vue';
import VButtonNew from 'portal/components/ui/button/VButtonNew.vue';
import ContentBlockHeaderButton
    from 'portal/components/common/content-block/ContentBlockHeaderButton.vue';
import TagList from 'portal/components/common/TagList.vue';
import VIcon from 'portal/components/ui/icon/VIcon.vue';

export default {
    name: 'ContentBlockWrapperWithHeader',
    components: { VIcon, TagList, ContentBlockHeaderButton, VButtonNew, ContentBlockHeader },
    props: {
        title: {
            type: String,
            default: null,
        },

        text: {
            type: String,
            default: null,
        },

        isWhite: {
            type: Boolean,
            default: false,
        },

        buttonLink: {
            type: String,
            default: '',
        },

        buttonText: {
            type: String,
            default: '',
        },

        target: {
            type: String,
            default: null,
        },

        tagsMobileOnly: {
            type: Boolean,
            default: false,
        },

        //  принимает массив или строку, если массив, то отображет первый элемент
        categories: {
            type: [String, Array],
            default: null,
        },

        type: {
            type: Number,
            default: NaN,
        },

        size: {
            type: String,
            default: 'small',
        },

        partner: {
            type: String,
            default: '',
        },

        /**
         * Определяет, является ли заголовок большого медиа ссылкой
         */
        linkTitle: {
            type: String,
            default: '',
        },
    },

    computed: {
        currentColor() {
            return this.isWhite ? 'base-100-500' : 'base-0-500';
        },

        classList() {
            return [
                {
                    [this.$style['_white']]: this.isWhite,
                    [this.$style['_bigTitle']]: this.text,
                },
            ];
        },
    },

};
