// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ContentBlockWrapperWithHeader_cwnpH{display:flex;flex-direction:column;justify-content:space-between}.ContentBlockWrapperWithHeader_cwnpH._white_xYeRU{background-color:#fff;border-radius:2rem;padding:2.4rem 3.2rem}@media(max-width:767.98px){.ContentBlockWrapperWithHeader_cwnpH._white_xYeRU{background:none;padding:0}}.ContentBlockWrapperWithHeader_cwnpH._bigTitle_omp7w .media-title{font-size:3.2rem;font-weight:500;letter-spacing:-.128rem;line-height:120%}@media(max-width:767.98px){.ContentBlockWrapperWithHeader_cwnpH._bigTitle_omp7w .media-title{font-size:2.4rem;font-weight:500;letter-spacing:-.096rem;line-height:2.64em;line-height:120%}}.headerSubtitle_CSi0A{color:#73788c;font-size:1.6rem;font-weight:400;letter-spacing:-.064rem;line-height:2.368rem;margin-top:1.2rem}.button_ln416.top_lZYXi{display:flex}@media(max-width:767.98px){.button_ln416.top_lZYXi{display:none}}.bottom_HSAUz .partner_n3liL{align-items:center;color:#73788c;display:flex;font-size:1.2rem;font-weight:400;letter-spacing:-.048rem;line-height:1.44rem;margin-top:2.4rem}.bottom_HSAUz .partner_n3liL .icon_3zmxR{color:#abaebb;margin-left:.8rem}@media(max-width:767.98px){.bottom_HSAUz .partner_n3liL{margin-top:2rem}}.bottom_HSAUz .buttonWrapper_yviKv{display:none}@media(max-width:767.98px){.bottom_HSAUz .buttonWrapper_yviKv{align-items:center;background-color:#17181c;border-radius:1.2rem;display:flex;justify-content:center;margin-top:2.4rem}.bottom_HSAUz .buttonWrapper_yviKv .text_6\\+pzz{color:#fff;font-size:1.6rem;font-weight:500;letter-spacing:-.064rem;line-height:1.92rem;margin-right:.8rem;text-transform:none}.bottom_HSAUz .buttonWrapper_yviKv .icon_3zmxR{color:#fff;height:2rem;width:2rem}}.tags_A-ht8._mobileOnly_l7S2n{display:none}@media(max-width:1023.98px){.tags_A-ht8._mobileOnly_l7S2n{display:flex}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "56px",
	"footer-h": "61.2rem",
	"mobile": "0",
	"tablet-sm": "768px",
	"tablet": "1024px",
	"laptop": "1280px",
	"desktop": "1440px",
	"large-desktop": "1920px",
	"ContentBlockWrapperWithHeader": "ContentBlockWrapperWithHeader_cwnpH",
	"_white": "_white_xYeRU",
	"_bigTitle": "_bigTitle_omp7w",
	"headerSubtitle": "headerSubtitle_CSi0A",
	"button": "button_ln416",
	"top": "top_lZYXi",
	"bottom": "bottom_HSAUz",
	"partner": "partner_n3liL",
	"icon": "icon_3zmxR",
	"buttonWrapper": "buttonWrapper_yviKv",
	"text": "text_6+pzz",
	"tags": "tags_A-ht8",
	"_mobileOnly": "_mobileOnly_l7S2n"
};
module.exports = ___CSS_LOADER_EXPORT___;
