// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media(max-width:767.98px){.OneToFourBlock_M0yjO{flex-direction:column;max-width:90vw}}.firstContainer_jcQZP{align-items:flex-start;display:flex;justify-content:space-between}.card_sGaZs>div:first-child{display:none}.card_sGaZs .taglist{display:none}.button_D94Kf{border-radius:1.2rem}@media(max-width:767.98px){.button_D94Kf{display:none}}.icon_Le3pC,.text_3LgvB{color:#17181c}.cardsTitle_T1MOT{font-size:1.8rem;font-weight:500;letter-spacing:-.072rem;line-height:2.16rem;margin-bottom:2.4rem}.cardsContainer_BekbV{display:grid;gap:2rem;grid-template-columns:repeat(2,1fr);grid-template-rows:repeat(2,1fr)}@media(max-width:767.98px){.cardsContainer_BekbV{display:none}}.headerSubtitle_-M9ee{color:#73788c;font-size:1.6rem;font-weight:400;letter-spacing:-.064rem;line-height:2.368rem;margin-top:1.2rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "56px",
	"footer-h": "61.2rem",
	"mobile": "0",
	"tablet-sm": "768px",
	"tablet": "1024px",
	"laptop": "1280px",
	"desktop": "1440px",
	"large-desktop": "1920px",
	"OneToFourBlock": "OneToFourBlock_M0yjO",
	"firstContainer": "firstContainer_jcQZP",
	"card": "card_sGaZs",
	"button": "button_D94Kf",
	"icon": "icon_Le3pC",
	"text": "text_3LgvB",
	"cardsTitle": "cardsTitle_T1MOT",
	"cardsContainer": "cardsContainer_BekbV",
	"headerSubtitle": "headerSubtitle_-M9ee"
};
module.exports = ___CSS_LOADER_EXPORT___;
