// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".button_WzKQD{border-radius:1.2rem}.button_WzKQD .text_UjP4f{color:#17181c;font-size:1.4rem;font-weight:500;letter-spacing:-.056rem;line-height:1.68rem;margin-right:.8rem;text-transform:none}.button_WzKQD .icon_4n97z{color:#73788c}@media(max-width:767.98px){.button_WzKQD{display:none}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "56px",
	"footer-h": "61.2rem",
	"mobile": "0",
	"tablet-sm": "768px",
	"tablet": "1024px",
	"laptop": "1280px",
	"desktop": "1440px",
	"large-desktop": "1920px",
	"button": "button_WzKQD",
	"text": "text_UjP4f",
	"icon": "icon_4n97z"
};
module.exports = ___CSS_LOADER_EXPORT___;
